/* Component Dependencies */
var propertyPortalNavigationTemplate = require('templates/propertyPortalNavigation.hbs');
var AriesComponent = require('libs/aries-component');
var Popup = require('libs/popup');

AriesComponent.create({
  type: 'propertyPortalNavigation',
  template: {
    'propertyPortalNavigation': propertyPortalNavigationTemplate
  },
  bindEvents: function () {
    var _self = this, $navElement, appTier;
    $navElement = _self.$el.closest('.mi-hsia-nav');
    if( !$navElement.hasClass('mi-hsia-nav-initialized') ) {
      _self.registerPopup();
      $navElement.addClass('mi-hsia-nav-initialized m-scroll-nav');
    }
  },
  /**
   * Register book your next stay popup
   * @return {void}
   */
  registerPopup: function() {
    var _self = this;
    var bookYourNextStayPopup = new Popup({
      items: {
        src: _self.$el.data('bookYourStayUri')
      },
      selector: '.mi-hsia-nav .mi-popover',
      sourceBlock: '.modal-content',
      alternateCloseMarkup: true
    });
    bookYourNextStayPopup.register();
  }
});